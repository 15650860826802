import './axios'
import {getDatas} from "../utils/Storage";

export const api = {
    applications : {
        list: '/applications/{language}',
        categories: '/categories/{language}',
        user: '/user',
    },
    notifications : {
        fr : {
            register: '/one-signal-subscribe',
            notifications: '/notifications',
            unreadall: '/notifications-unreadall',
            notificationhasbeenread: '/notifications/{id}/is-read',
            notification: '/notifications/{id}',
        }
    }
};
