import React, {createContext, useContext, useReducer} from 'react';

export const GlobalContext = createContext();

const initialState = {
    currentLanguage: localStorage.getItem('lng') || 'fr',
    localizationContent: null,
};

const reducer = (state, action) => {

    switch (action.type) {
        case 'changeLanguage':
            localStorage.setItem('lng', action.value);
            return {
                ...state,
                currentLanguage: action.value
            };
        case 'setLocalizationContent':
            return {
                ...state,
                localizationContent: action.value
            };

        default:
            return state;
    }
};

export const GlobalProvider = (props) => (
    <GlobalContext.Provider value={useReducer(reducer, initialState)}>
        {props.children}
    </GlobalContext.Provider>
);

export const useStateValue = () => useContext(GlobalContext);
