import React, {useState, useRef} from 'react';
import Logo from '../../assets/images/logo.svg';

import "./Header.scss";
import Icon from "../Icon/Icon";
import useOutsideClick from "../../utils/OutsideClick";
import UserService from "../../services/UserService";
import {useStateValue} from "../../providers/GlobalProvider";


const Header = props => {

    const [contextMenu, setContextMenu] = useState({isOpened: false});
    const [{currentLanguage, localizationContent}, dispatch] = useStateValue();

    const openContextMenu = () => {
        setContextMenu({isOpened: true});
    };

    const closeContextMenu = () => {
        setContextMenu({isOpened: false});
    };

    const changeLanguage = (languageSlug) => {
        dispatch({
            type: 'changeLanguage',
            value: languageSlug
        });
    }

    const logOut = () => {
        UserService.logout();
    };

    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef, () => closeContextMenu());


    return (
        <div className="header">
            <img src={Logo} alt={'Logo Tryba'} className={'header__logo'}/>
            <div className={'header__actions'}>

                <div className={'header__actions__item header__language-switch'}>
                    <div className={"header__language-switch__item " + (currentLanguage === 'fr' ? 'active':null)} onClick={() => changeLanguage('fr')}>FR</div>
                    <div className={"header__language-switch__item " + (currentLanguage === 'nl' ? 'active':null)} onClick={() => changeLanguage('nl')}>NL</div>
                </div>


                <div className={'header__actions__item header__actions__item--icon header__profile'}
                     onClick={() => openContextMenu()}>
                    <Icon icon={'espaceclient'} size={18}/>

                    {contextMenu.isOpened ?
                        <ul className={'header__profile__context-menu'} ref={wrapperRef}>
                            <li className={'header__profile__context-menu__item'} onClick={() => logOut()}>{(localizationContent.logout ? localizationContent.logout : "Se déconnecter")}</li>
                        </ul>
                        :
                        null
                    }

                </div>
                <div className={'header__actions__item header__account-info'} onClick={() => openContextMenu()}>
                    <p className={'p--small header__account-info__name'}>{UserService.user.get().firstname ?? 'Prénom'} {UserService.user.get().lastname ?? 'Nom'}</p>
                    <p className={'header__account-info__location'}>{UserService.user.get().company ?? 'Tryba'}</p>
                </div>

            </div>
        </div>
    );
};

export default Header;
