import React from "react";
import PropTypes from "prop-types";
import AuthMsalProvider from "../../providers/AuthMsalProvider";
import UserService from "../../services/UserService";
import {IonButton} from "@ionic/react";
import DashboardLoader from "../DashboardLoader/DashboardLoader";
import {ORIGIN_SSO_SERVER_URL} from "../../config/axios";
import ErrorService from "../../services/ErrorService";
import {Redirect} from "react-router-dom";

const Json = ({ data }) => <pre>{JSON.stringify(data, null, 4)}</pre>;

class AuthMsal extends React.Component {
    static propTypes = {
        afterRedirection: PropTypes.bool,
        account: PropTypes.object,
        emailMessages: PropTypes.object,
        error: PropTypes.string,
        graphProfile: PropTypes.object,
        onSignIn: PropTypes.func.isRequired,
        onSignOut: PropTypes.func.isRequired,
        onRequestEmailToken: PropTypes.func.isRequired
    };

    openSamlPopup = () => {
        const w = 370;
        const h = 410;
        const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;
        const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen?.width;
        const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen?.height;
        const left = (width - w) / 2 + dualScreenLeft
        const top = (height - h) / 2 + dualScreenTop
        window.open('/saml-login', "iframe-sso", `
                  scrollbars=yes,
                  width=${w}, 
                  height=${h},
                  top=${top}, 
                  left=${left}
                `)
    }



    displayError = () => {
        if (this.props.error) {
            if (this.props.error.substr(0, 17) === 'Login_In_Progress') {
                ErrorService.add('Une fenêtre de connexion était déjà ouverte ! Veuillez recommencer pour l\'atteindre', {color: 'danger', duration: 2000, position: 'top'});
            }
        }
        return null;
    };

    render() {

        return (
            <>
                <div className={"auth-msal"}>
                    <section>
                        {!this.props.account || UserService.user.get().askForMsalReconnection ? (
                            <button className={"btn btn--small btn--primary"} onClick={(e) => { e.stopPropagation(); this.props.onSignIn(); return false; }} >Se connecter</button>
                        ) : null}
                        {this.props.error && (
                            this.displayError(this.props.error) &&
                            <IonButton onClick={(e) => { this.props.onSignIn(); }} color="light">Réessayer</IonButton>
                        )}
                    </section>
                </div>
                {this.props.account && !this.props.error && !UserService.user.get().askForMsalReconnection && (
                    <>
                        {
                            UserService.user.get().askForReconnection ?
                                // <IonButton color="light" onClick={(e) => { this.props.onSignIn(); }}>Se reconnecter</IonButton>
                                <button className={"btn btn--small btn--primary"} onClick={(e) => { e.stopPropagation(); this.props.onSignIn(); return false; }} >Se reconnecter</button>
                                :
                                UserService.user.get().pleaseRetry ?
                                    // <IonButton onClick={(e) => { this.props.onSignIn(); }} color="light">Réessayer</IonButton>
                                    <button className={"btn btn--small btn--primary"} onClick={(e) => { e.stopPropagation(); this.props.onSignIn(); return false; }} >Réessayer</button>
                                    :
                                    !this.props.afterRedirection ? <div className={"redirect-dashboard"}>
                                        <h2 className={'h3'}>Bonjour {UserService.user.get().firstname ?? 'Prénom'},</h2>
                                        <p className={"p--medium"}>Prêt à démarrer une nouvelle journée ?</p>
                                        <button className={"btn btn--small btn--primary"} onClick={(e) => { e.stopPropagation(); this.openSamlPopup(); return false; }} >Accèder a mon bureau</button>
                                    </div>
                                    : null

                        }
                        {
                            this.props.afterRedirection ? <Redirect to={'/'} />: null
                        }

                    </>
                )}
            </>
        );
    }
}

export default AuthMsalProvider(AuthMsal);
