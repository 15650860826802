import { UserAgentApplication, LogLevel } from "msal";

export const requiresInteraction = errorMessage => {
    if (!errorMessage || !errorMessage.length) {
        return false;
    }
    return (
        errorMessage.indexOf("consent_required") > -1 ||
        errorMessage.indexOf("interaction_required") > -1 ||
        errorMessage.indexOf("login_required") > -1
    );
};

export const fetchMsGraph = async (url, accessToken) => {
    const response = await fetch(url, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });
    return response.json();
};

export const isIE = () => {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ") > -1;
    const msie11 = ua.indexOf("Trident/") > -1;
    return msie || msie11;
};

export const GRAPH_SCOPES = {
    OPENID: "openid",
    PROFILE: "profile",
    USER_READ: "User.Read",
    MAIL_READ: "Mail.Read"
};

export const GRAPH_ENDPOINTS = {
    ME: "https://graph.microsoft.com/v1.0/me",
    MAIL: "https://graph.microsoft.com/v1.0/me/messages"
};

export const GRAPH_REQUESTS = {
    LOGIN: {
        scopes: [
            GRAPH_SCOPES.OPENID,
            GRAPH_SCOPES.PROFILE,
            GRAPH_SCOPES.USER_READ
        ]
    },
    EMAIL: {
        scopes: [GRAPH_SCOPES.MAIL_READ]
    }
};

const authLocal = {
    clientId: "2e6d22b8-821c-4d9e-913b-d3b415a2f336",
    authority: "https://login.microsoftonline.com/8c86a4d9-5f4e-46d4-bde0-3502e9c3fdda",
    validateAuthority: true,
    postLogoutRedirectUri: "https://mon-bureau.tryba.wip/logout",
    //postLogoutRedirectUri: "https://localhost:3000",
    navigateToLoginRequestUrl: false,
    // redirectUri: "http://localhost:3000/apps/app-tablette/build#/login",
    //redirectUri: "http://localhost:3000/login",
    redirectUri: "https://mon-bureau.tryba.wip/login",
};

const authPreprod = {
    clientId: "d7198c0a-c709-47f1-a031-3ddc49819316",
    authority: "https://login.microsoftonline.com/8c86a4d9-5f4e-46d4-bde0-3502e9c3fdda",
    validateAuthority: true,
    postLogoutRedirectUri: "https://recette-monbureau.tryba.be/logout",
    navigateToLoginRequestUrl: false,
    redirectUri: "https://recette-monbureau.tryba.be/login",
};

const authProd = {
    clientId: "b8d55b97-deaa-484e-9af8-d454a450840d",
    authority: "https://login.microsoftonline.com/8c86a4d9-5f4e-46d4-bde0-3502e9c3fdda",
    validateAuthority: true,
    postLogoutRedirectUri: "https://monbureau.tryba.be/logout",
    navigateToLoginRequestUrl: false,
    redirectUri: "https://monbureau.tryba.be/login",
};

let authConfig = null;

switch(window.location.host) {
    case 'localhost:3000':
    case '0.0.0.0:8383':
    case 'localhost:8383':
    case 'mon-bureau.tryba.wip':
        authConfig = {
            auth: authLocal,
            cache: {
                cacheLocation: "sessionStorage",
                storeAuthStateInCookie: isIE()
            },
            system: {
                navigateFrameWait: 0,
                loggerOptions: {
                    loggerCallback: (level, message, containsPii) => {
                        if (containsPii) {
                            return;
                        }
                        switch (level) {
                            case LogLevel.Error:
                                console.error(message);
                                return;
                            case LogLevel.Info:
                                console.info(message);
                                return;
                            case LogLevel.Verbose:
                                console.debug(message);
                                return;
                            case LogLevel.Warning:
                                console.warn(message);
                                return;
                            default:
                                return;
                        }
                    }
                }
            }
        };
        break;
    case 'recette-monbureau.tryba.be':
        authConfig = {
            auth: authPreprod,
            cache: {
                cacheLocation: "sessionStorage",
                storeAuthStateInCookie: isIE()
            },
            system: {
                navigateFrameWait: 0,
                loggerOptions: {
                    loggerCallback: (level, message, containsPii) => {
                        if (containsPii) {
                            return;
                        }
                        switch (level) {
                            case LogLevel.Error:
                                console.error(message);
                                return;
                            case LogLevel.Info:
                                console.info(message);
                                return;
                            case LogLevel.Verbose:
                                console.debug(message);
                                return;
                            case LogLevel.Warning:
                                console.warn(message);
                                return;
                            default:
                                return;
                        }
                    }
                }
            }
        };
        break;
    case 'monbureau.tryba.be':
        authConfig = {
            auth: authProd,
            cache: {
                cacheLocation: "sessionStorage",
                storeAuthStateInCookie: isIE()
            },
            system: {
                navigateFrameWait: 0,
                loggerOptions: {
                    loggerCallback: (level, message, containsPii) => {
                        if (containsPii) {
                            return;
                        }
                        switch (level) {
                            case LogLevel.Error:
                                console.error(message);
                                return;
                            case LogLevel.Info:
                                console.info(message);
                                return;
                            case LogLevel.Verbose:
                                console.debug(message);
                                return;
                            case LogLevel.Warning:
                                console.warn(message);
                                return;
                            default:
                                return;
                        }
                    }
                }
            }
        };
        break;
}

export const msalConfig = authConfig;

export let msalApp = new UserAgentApplication(msalConfig);
