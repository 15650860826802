import React, {useEffect, useState} from 'react';
import Router from "./Router";
import ScreensizeModal from "../components/ScreensizeModal/ScreensizeModal";
import SplashScreen from "../components/SplashScreen/SplashScreen";
import UserService from "../services/UserService";
import ApplicationService from "../services/ApplicationService";
import BookmarkService from "../services/BookmarkService";
import {useStateValue} from "../providers/GlobalProvider";
import {getDatas, setDatas} from "../utils/Storage";

/**
 * When app loading, we have to do some stuff before displaying home
 * 1) get user local datas
 * 2) get applications local datas
 * 3) get bookmarks local datas
 * 4) get notifications local datas
 * @returns {*}
 */
export default function Root() {

    const [init, setInit] = useState(false);
    const [animationClass, setAnimationClasse] = useState('loading');
    const [tempCurrentLanguage, setTempCurrentLanguage] = useState(null);
    const [{currentLanguage}] = useStateValue();


    useEffect(() => {

        if (!init) {
           const dateStartLoadind = new Date();
           UserService.init().then(() => {
               ApplicationService.init().then(() => {
                   BookmarkService.init().then(() => {
                       finishInit(dateStartLoadind);
                   });
               });
           })
        }

        if(currentLanguage !== tempCurrentLanguage) {
            setDatas('language', currentLanguage);
            setTempCurrentLanguage(currentLanguage);
            setTimeout(() => {
                ApplicationService.init().then(() => {
                    BookmarkService.init();
                });
            }, 200);

        }

    }, [init, currentLanguage]);

    const finishInit = (dateStartLoadind) => {
        const dateEndLoadind = new Date();
        const diff = dateEndLoadind.getTime() - dateStartLoadind.getTime();
        setTimeout(() => {
            setAnimationClasse('loaded');
            setTimeout(() => {
                setInit(true);
            }, 400);
        }, ( diff > 0 ? 400 - diff : 1 ) );
    };

    return (
        <div className="main">
            {
                init ?
                    <>
                        <ScreensizeModal/>
                        <Router/>
                    </>
                    :
                <SplashScreen className={animationClass} />
            }
        </div>
    );

}
