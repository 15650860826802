import React, {useEffect, useState} from 'react';

import "./Login.scss";
import Title from "../../components/Title/Title";
import sumoLogin from '../../assets/images/sumo-login-big.png';
import AuthMsal from "../../components/AuthMsal/AuthMsal";

const Login = props => {

    const [loadedClass, setLoadedClass] = useState(false);

    const loginStyle = {
        backgroundImage: 'url(' + sumoLogin + ')',
    };

    useEffect(() => {
        if(!loadedClass) {
            setTimeout(() => {
                setLoadedClass(true);
            }, 100);
        }
    });

    return (
        <div className={'login' + (loadedClass ? ' login--loaded' : '')}>
            <div className={'container-fluid'}>
                <div className={'row'}>
                    <div className={'login__content'}>
                        <Title type={'h5'} headline={true}>Tableau de bord Tryba</Title>
                        <Title type={'h1'}>Bienvenue sur votre bureau TRYBA</Title>
                        {/*<p className={'p--medium'}>Retrouvez en 1 clic toutes vos applications et tous vos sites métier.</p>*/}
                    </div>
                    <div className={'login__form'} style={loginStyle}>
                        <div className={'w-iframe'}>
                            <AuthMsal />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
